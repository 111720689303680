<template>
  <div class="info" id="watermark">
    <top-bar :title="'疫情隔离'" :left="true"></top-bar>
    <!-- 性别 -->
    <van-popup v-model="sexShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.sexList" value-key="label"  @confirm="sexConfirm"  @cancel="sexShow = false"/>
    </van-popup>

    <!-- 出生日期 -->
    <van-popup v-model="birthTimeShow" position="bottom">
      <van-datetime-picker v-model="submitData.birthday" type="date" title="请选择" :formatter="formatDate"
                           @cancel="birthTimeShow = false" @confirm="birthTimeConfirm" :maxDate="maxDate"/>
    </van-popup>

    <!-- 隔离时间 -->
    <van-popup v-model="isolateTimeShow" position="bottom">
      <van-datetime-picker v-model="submitData.isolateTime" type="date" title="请选择" :formatter="formatDate"
                           @cancel="isolateTimeShow = false" @confirm="isolateTimeConfirm" :maxDate="maxDate"/>
    </van-popup>

    <!--  隔离人员类型  -->
    <van-popup v-model="userTypeShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.userTypeList" value-key="label"  @confirm="userTypeConfirm"  @cancel="userTypeShow = false"/>
    </van-popup>

    <!-- 所在小区 -->
    <van-popup v-model="CommunityShow"  position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
    </van-popup>

    <!-- 居住房屋 -->
    <van-popup v-model="buildingShow" position="bottom">
      <van-cascader v-model="submitData.building" title="请选择" :options="selectData.buildingList"
                    @close="buildingShow = false"  @change="buildingChange" @finish="buildingFinish" :field-names="fieldNames"/>
    </van-popup>

    <!-- 和户主关系 -->
    <van-popup v-model="relationshipShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.relationshipList" value-key="label"  @confirm="relationshipConfirm"  @cancel="relationshipShow = false"/>
    </van-popup>

    <!-- 户籍性质 -->
    <van-popup v-model="registryTypeShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.registryTypeList" value-key="label"  @confirm="registryTypeConfirm"  @cancel="registryTypeShow = false"/>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.mobile" label="手机号码" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.userName" label="姓名" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.idNumber" label="身份证号" placeholder="请输入" input-align="right" @blur="bindInput"/></van-cell>
      <van-cell title="性别" :value="selectMsg.sexStr" @click="sexShow = true" is-link :value-class="{'value-common':selectMsg.sexStr=='请选择'}"/>
      <van-cell><van-field v-model="selectMsg.nationalityStr" label="国籍" placeholder="请输入" input-align="right" @click="nationalitySelect"/></van-cell>
      <van-cell><van-field v-model="selectMsg.nationStr" label="民族" placeholder="请输入" input-align="right" @click="nationSelect"/></van-cell>
      <van-cell title="出生日期" :value="selectMsg.birthday" @click="birthTimeShow = true" is-link :value-class="{'value-common':selectMsg.birthday=='请选择'}"/>
      <van-cell title="隔离时间" :value="selectMsg.isolateTime" @click="isolateTimeShow = true" is-link :value-class="{'value-common':selectMsg.isolateTime=='请选择'}"/>
      <van-cell title="隔离人员类型" :value="selectMsg.userTypeStr" @click="userTypeShow = true" is-link :value-class="{'value-common':selectMsg.userTypeStr=='请选择'}"/>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.company" label="工作单位" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.cardAdd" label="户籍地址" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.nowAddress" label="现居地" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell title="居住小区" :value="selectMsg.Community" @click="CommunityShow = true" is-link :value-class="{'value-common':selectMsg.Community=='请选择'}"/>
      <van-cell title="居住房屋" :value="selectMsg.buildingStr" @click="buildingShow = true" is-link :value-class="{'value-common':selectMsg.buildingStr=='请选择'}"/>
      <van-cell title="和户主关系" :value="selectMsg.relationshipStr" @click="relationshipShow = true" is-link :value-class="{'value-common':selectMsg.relationshipStr=='请选择'}"/>
      <van-cell title="户籍性质" :value="selectMsg.registryTypeStr" @click="registryTypeShow = true" is-link :value-class="{'value-common':selectMsg.registryTypeStr=='请选择'}"/>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.emContact" label="紧急联系人" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell><van-field clearable clear-trigger="always" v-model="submitData.emMobile" label="联系人手机号" placeholder="请输入" input-align="right"/></van-cell>
      <van-cell title="头像" is-link>
        <van-uploader v-model="fileList" @delete="uploaddel" :after-read="uploadRead"
                      :before-read="beforeUpload" :show-upload="true" :max-count="1">
          <div v-if="submitData.headImage == ''">上传文件</div>
        </van-uploader>
      </van-cell>
    </van-cell-group>
    <van-row class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="submit">确认添加</van-button>
      </van-col>
    </van-row>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {beforeFileUpload, formatterDate, getBirthdayFromIdCard, getSexFromIdCard, IdentityCodeValid} from '@/utils/utils'
import {isNumber} from '@/utils/validate'

import { mapState } from 'vuex'
export default {
  data () {
    return {
      detailData: {},
      // 性别选择
      sexShow: false,
      // 出生日期选择
      birthTimeShow: false,
      // 隔离时间选择
      isolateTimeShow: false,
      // 隔离人员类型选择
      userTypeShow: false,
      // 所在小区选择
      CommunityShow: false,
      // 居住房屋选择
      buildingShow: false,
      // 和户主关系选择
      relationshipShow: false,
      // 户籍性质
      registryTypeShow: false,
      selectMsg: {
        //性别
        sexStr: '女',
        //出生
        birthday: '',
        //隔离时间
        isolateTime: '',
        //隔离人员
        userTypeStr: '请选择',
        //所在小区
        Community: '请选择',
        //居住房屋
        buildingStr: '请选择',
        //和户主关系
        relationshipStr: '请选择',
        //户籍性质
        registryTypeStr: "请选择",
        //国籍
        nationalityStr: '中国',
        //民族
        nationStr: '汉族',
      },
      submitData: {
        id: 0,
        orgId:0,
        //手机号码
        mobile: '',
        //姓名
        userName: '',
        //身份证号
        idNumber: '',
        //国籍
        nationality: '59',
        //民族
        nation: '2',
        //性别
        sex: '2',
        //出生
        birthday: '',
        //隔离时间
        isolateTime: '',
        //隔离人员
        userType: '',
        //工作单位
        company: '',
        //户籍地址
        cardAdd: '',
        //现居地
        nowAddress: '',
        //所在小区
        subarea:'',
        //居住房屋
        houseId: '',
        //和户主关系
        relationship: '',
        //户籍性质
        registryType: '',
        //紧急联系人
        emContact: '',
        //联系人手机号
        emMobile: '',
        userId:'',
        //头像
        headImage: '',
        suffix: '',
        //证件类别
        idNumberType: '1',
        isolationId: ''
      },
      selectData: {
        sexList: [{value: '2', label: '女'}, {value: '1', label: '男'}],
        userTypeList: [{value: '1', label: '国内中高风险市低风险区进入'}, {value: '2', label: '境外进入'}],
        CommunityList: [],
        buildingList: [],
        relationshipList: [],
        registryTypeList: []
      },
      resHouses: [{
        subArea: '',
        houseId: '',
        relationship: '',
      }],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      maxDate: '',
      fileList: []
    }
  },
  computed:{...mapState(['ProblemId'])},
  components :{
    topBar
  },
  methods: {
    init () {
      if (this.$store.state.nationality) {
        this.selectMsg.nationalityStr = this.$store.state.nationality.label
        this.submitData.nationality = this.$store.state.nationality.value
      }
      if (this.$store.state.nation) {
        this.selectMsg.nationStr = this.$store.state.nationality.label
        this.submitData.nation = this.$store.state.nationality.value
      }
      let nowDate = new Date()
      this.maxDate = nowDate
      this.getRelationShip()
      this.getRegistryType()
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.CommunityList = data.subAreaList
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    submit () {
      if (isNumber(this.submitData.mobile) === false) {return this.$toast.fail('失败:手机号必须为数字');}
      if (IdentityCodeValid (this.submitData.idNumber) === false) {return this.$toast.fail('失败:身份证号格式错误');}
      if ( this.selectMsg.relationshipStr === '请选择') {return this.$toast.fail('失败:请先选择与户主关系');}
      if ( this.selectMsg.isolateTime === '请选择') {return this.$toast.fail('失败:请先选择隔离时间');}
      if ( this.submitData.userName === '') {return this.$toast.fail('失败:请先输入姓名');}
      if ( this.submitData.idNumber === '') {return this.$toast.fail('失败:请先输入身份证号');}
      if ( this.selectMsg.userTypeStr === '请选择') {return this.$toast.fail('失败:请先选择隔离人员类型');}
      if (this.selectMsg.registryTypeStr === '请选择') {return this.$toast.fail('失败:请先选择户籍类型');}
      if (this.submitData.houseId === '') {return this.$toast.fail('失败:请先选择居住房屋');}
      this.$http({
        url: this.$http.adornUrl('/wxapp/disease/res/resSave'),
        method: 'post',
        data : this.$http.adornParams({
          name: this.submitData.userName,
          idNumber: this.submitData.idNumber,
          idNumberType: this.submitData.idNumberType,
          mobile: this.submitData.mobile,
          regAddress: this.submitData.cardAdd,
          headImg: this.submitData.headImage,
          registryType: Number(this.submitData.registryType),
          orgId: this.$orgId,
          temporaryHeadImg: this.submitData.headImage,
          temporaryHeadImgSuffix: this.submitData.suffix,
          sex: Number(this.submitData.sex),
          nationality: this.submitData.nationality,
          nation: this.submitData.nation,
          birthday: this.submitData.birthday,
          nowAddress: this.submitData.nowAddress,
          company: this.submitData.company,
          // source: that.data.createUserId,
          emMobile: this.submitData.emMobile,
          emContact: this.submitData.emContact,
          isolationDate: this.submitData.isolateTime,
          houseId: this.submitData.houseId,
          isolationId: this.submitData.isolationId,
          userType: Number(this.submitData.userType)
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.uploadHouse(data.userId)
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    uploadHouse (userId) {
      this.resHouses[0].subArea = this.submitData.subarea
      this.resHouses[0].houseId = this.submitData.houseId
      this.resHouses[0].relationship = this.submitData.relationship
      let resHousess = []
      for (let index in this.resHouses) {
        let resHouse = this.resHouses[index]
        let resHousee = {
          'id': 0,
          'userId': userId,
          'subarea': resHouse.subArea,
          'houseId': resHouse.houseId,
          'relationship': resHouse.relationship,
        }
        resHousess.push(resHousee)
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/houseRes/saveOrUpdate'),
        method: 'post',
        data: this.$http.adornParams({
          resHouses: JSON.stringify(resHousess)
        }, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success ('新增成功');
          this.$router.go(-1)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    //身份证输入框事件
    bindInput (e) {
      let value = e.currentTarget.value
     if (value.length == 18 || value.length == 15) {
       this.selectMsg.birthday = getBirthdayFromIdCard(value)
       this.submitData.birthday = getBirthdayFromIdCard(value)
       this.selectMsg.sexStr = getSexFromIdCard(value) == 1 ? '男' : '女'
       this.submitData.sex = getSexFromIdCard(value) + ''
     }
    },
    // 删除图片
    uploaddel(file, detail) {
      this.submitData.headImage = ''
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/inspectionPicture/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.headImage = data.fileMessage.relativePath
            this.submitData.suffix = data.fileMessage.suffix
            console.log(this.submitData.headImage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
    },
    // 和户主关系列表
    getRelationShip() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual/relationships'),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.relationshipList = data.relationships
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 户籍性质
    getRegistryType() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual/registryType'),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          data.registryTypes.map(item => {
            item.value = item.value + ''
          })
          this.selectData.registryTypeList = data.registryTypes
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 小区选择
    CommunityConfirm (value) {
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        // // 获取房屋列表
        // getBuildingList() {
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
            method: 'get',
            params: this.$http.adornParams({
              id: this.submitData.subarea,
              depth: 1,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.selectData.buildingList = data.liveWhichTree
            } else {
              this.$toast.fail(data.msg)
            }
          })
        // },
      }
      this.CommunityShow = false
    },
    // 居住房屋选择
    buildingChange (value, index) {
      if (this.submitData.subarea === '') {
        this.$toast.fail('请先选择小区!')
      } else {
        if (value.value && value.tabIndex == 0) {
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
            method: 'get',
            params: this.$http.adornParams({
              id: value.value,
              depth: 2,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.liveWhichTree.length > 0) {
                this.selectData.buildingList.map(item => {
                  if (value.value == item.value) {
                    item.children = data.liveWhichTree
                  }
                })
                this.buildingShow = true
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        } else if (value.value && value.tabIndex == 1){
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
            method: 'get',
            params: this.$http.adornParams({
              id: value.value,
              depth: 3,
            })
          }).then(({data}) => {
            if (data && data.code === 0) {
              if (data.liveWhichTree.length > 0) {
                this.selectData.buildingList.map(item => {
                  if (item.children) {
                    item.children.map(items => {
                      if (value.value == items.value) {
                        items.children = data.liveWhichTree
                      }
                    })
                  }
                  this.buildingShow = true
                })
                this.buildingShow = true
              }
            } else {
              this.$toast.fail(data.msg)
            }
          })
        }
      }
    },
    // 居住房屋完成
    buildingFinish(value) {
      if (value) {
        let options = value.selectedOptions
        this.selectMsg.buildingStr = ''
        let houseId = -1;
        let option = options[options.length - 1];
        if (option.depth == 2 && option.children == null) {
          houseId = option.value
        } else if (option.depth == 3 && option.children == null) {
          houseId = -1
        } else if (option.depth == 4 && option.children == null) {
          houseId = option.value
        } else {
          houseId = -1
        }
        this.submitData.houseId = houseId
        options.map(item => {
          this.selectMsg.buildingStr += item.label
        })
      }
      this.buildingShow = false
    },
    // 性别选择
    sexConfirm (value) {
      if(value) {
        this.selectMsg.sexStr = value.label
        this.submitData.sex = value.value
      }
      this.sexShow = false
    },
    // 出生日期选择
    birthTimeConfirm(value) {
      if (value) {
        this.selectMsg.birthday = formatterDate(value)
        this.submitData.birthday = formatterDate(value)
      }
      this.birthTimeShow = false
    },
    // 隔离时间选择
    isolateTimeConfirm(value) {
      if (value) {
        this.selectMsg.isolateTime = formatterDate(value)
        this.submitData.isolateTime = formatterDate(value)
      }
      this.isolateTimeShow = false
    },
    userTypeConfirm (value) {
      if (value) {
        this.selectMsg.userTypeStr = value.label
        this.submitData.userType = value.value
      }
      this.userTypeShow = false
    },
    // 和户主关系选择
    relationshipConfirm (value) {
      if (value) {
        this.selectMsg.relationshipStr = value.label
        this.submitData.relationship =  value.value
      }
      this.relationshipShow = false
    },
    registryTypeConfirm (value) {
      if (value) {
        this.selectMsg.registryTypeStr = value.label
        this.submitData.registryType = value.value
      }
      this.registryTypeShow = false
    },
    //国籍跳转选择
    nationalitySelect() {
      this.$router.push('/nationality')
    },
    //民族跳转选择
    nationSelect() {
      this.$router.push('/nation')
    },
    onCancel() {
    },
  },
  created () {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    document.querySelector('html').style.backgroundColor = '#fff'
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
